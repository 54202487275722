import * as THREE from 'three'

import { Water } from 'three/examples/jsm/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import renderFlamingos from './birds'

var camera, scene, renderer;
var water, sun, mesh;

function renderOcean(canvas, drawing, element) {

  renderer = new THREE.WebGLRenderer({ canvas });
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );

  //

  scene = new THREE.Scene()

  const fov = 65
  const scale = 0.1
  const elevation = (scale * element.offsetHeight) / 2 + 25 * scale
  camera = new THREE.PerspectiveCamera( fov, window.innerWidth / window.innerHeight, 1, 20000 );

  // Compute a Z value so the plane z=0, has units corresponding to screen pixels. E.g. an object width 300, takes up 300px of screen width.
  const fovRadians = fov * (Math.PI / 180)
  const z = scale * ( window.innerHeight / ( 2 * Math.tan(fovRadians / 2) ) )

  camera.position.set( 0, elevation, z );
  //camera.lookAt(0,0,0)

  sun = new THREE.Vector3();

  var waterGeometry = new THREE.PlaneBufferGeometry( 10000, 10000 );

  water = new Water(
    waterGeometry,
    {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: new THREE.TextureLoader().load( 'img/waternormals.jpg', function ( texture ) {

        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

      } ),
      alpha: 1.0,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: scene.fog !== undefined
    }
  );

  water.rotation.x = - Math.PI / 2;

  // Skybox

  var sky = new Sky();
  sky.scale.setScalar( 10000 );
  scene.add( sky );

  var uniforms = sky.material.uniforms;

  uniforms[ 'turbidity' ].value = 10;
  uniforms[ 'rayleigh' ].value = 2;
  uniforms[ 'mieCoefficient' ].value = 0.005;
  uniforms[ 'mieDirectionalG' ].value = 0.8;

  var parameters = {
    inclination: 0.499,
    azimuth: 0.205
  };

  var pmremGenerator = new THREE.PMREMGenerator( renderer );

  function updateSun() {

    var theta = Math.PI * ( parameters.inclination - 0.5 );
    var phi = 2 * Math.PI * ( parameters.azimuth - 0.5 );

    sun.x = Math.cos( phi );
    sun.y = Math.sin( phi ) * Math.sin( theta );
    sun.z = Math.sin( phi ) * Math.cos( theta );

    sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
    water.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();

    scene.environment = pmremGenerator.fromScene( sky as any ).texture;
  }

  updateSun();

  const texture = new THREE.Texture(drawing);

  console.log(scale * element.offsetWidth * 0.88, scale * element.offsetHeight * 0.88)
  var planeGeo = new THREE.PlaneBufferGeometry(scale * element.offsetWidth * 0.88, scale * element.offsetHeight * 0.88)
  const planeMat = new THREE.MeshStandardMaterial({
    map: texture,
    side: THREE.DoubleSide,
    emissive: 0xFFFFFF,
    emissiveMap: texture
  })
  var planeMesh = new THREE.Mesh(planeGeo, planeMat)
  console.log({ elevation })
  planeMesh.position.set(0, elevation, 0)

  planeMesh.receiveShadow = true
  scene.add(planeMesh)

  renderFlamingos(scene, renderer, () => scene.add( water ))

  var i = 0
  function animate() {
    texture.needsUpdate = true

    requestAnimationFrame( animate );
    render();
    i++;
  }

  function render() {
    var time = performance.now() * 0.001;
    water.material.uniforms[ 'time' ].value += 1.0 / 120.0;

    renderer.render( scene, camera );
  }

  function onWindowResize() {

    const elevation = (scale * element.offsetHeight) / 2 + 25 * scale
    const z = scale * ( window.innerHeight / ( 2 * Math.tan(fovRadians / 2) ) )

    camera.position.set( 0, elevation, z );

    planeGeo = new THREE.PlaneBufferGeometry(scale * element.offsetWidth * 0.88, scale * element.offsetHeight * 0.88)
    planeMesh = new THREE.Mesh(planeGeo, planeMat)
    planeMesh.position.set(0, elevation, 0)

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );

  }
  window.addEventListener( 'resize', onWindowResize, false );

  animate()

}

export default renderOcean
