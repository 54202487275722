import renderOcean from 'Login/3d/ocean'
import renderFlamingos from 'Login/3d/birds'

import './Login.scss'
import html2canvas from 'html2canvas'
import axios from 'axios'

export default () => {
  const canvasRef = useRef(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const loginRef = useRef(null)
  const drawingRef = useRef(null)
  const birdsRef = useRef(null)
  const [zoom, setZoom] = useState(false)
  const [error, setError] = useState(null)

  function redraw() {
    html2canvas(loginRef.current, { allowTaint: true, backgroundColor: '#222'}).then(function(canvas) {
      drawingRef.current.src = canvas.toDataURL()
    })
  }

  useEffect(() => {
    const canvas = canvasRef.current
    renderOcean(canvas, drawingRef.current, loginRef.current)
    setTimeout(() => setZoom(true), 20)
  }, []);

  useEffect(() => {
    redraw()
  }, [error])

  function updateEmail(value) {
    setEmail(value)
    setError(null)
  }

  function updatePassword(value) {
    setPassword(value)
  }

  function onSubmit(e) {
    e.preventDefault()
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    const params = { user: { email, password }}
    const options = { headers: { 'X-CSRF-Token': csrf } }

    axios.post('/users/sign_in', params, options)
      .then(() => window.location.href = '/admin')
      .catch(() => setError('Invalid email / password'))
  }

  function onGoogleClick() {
    window.PipeframeData = {}
    window.PipeframeData.oauthFailCallback = () => {
      setError("OAuth login failed. Are you in the right domain?")
    }

    var newwindow;
    var height = 400;
    var width = 300;
    var screenX     = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft,
        screenY     = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop,
        outerWidth  = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.body.clientWidth,
        outerHeight = typeof window.outerHeight != 'undefined' ? window.outerHeight : (document.body.clientHeight - 22),
        left        = screenX + ((outerWidth - width) / 2),
        top         = screenY + ((outerHeight - height) / 2.5),
        features    = ('width=' + String(width) + ',height=' + String(height) + ',left=' + String(left) + ',top=' + String(top));

        newwindow = window.open('/users/auth/google_oauth2', 'Login', features);

    if (window.focus)
      newwindow.focus();

    return false;
  }

  return <div className='Login'>
    <canvas id="canvas" ref={canvasRef}></canvas>
    <div id="birds" ref={birdsRef} />
    <img id="drawing" ref={drawingRef} />
    <form className='login-container' onSubmit={onSubmit}>
      <div className={`login-glass ${zoom ? 'zoom' : ''}`} ref={loginRef}>
        <div className='login-box'>
          <div className='logo'>
            <img src="logo.png" />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="Email"
              value={email}
              onChange={e => updateEmail(e.target.value)}
              required={true}
              />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={password}
              onChange={e => updatePassword(e.target.value)}
              required={true}
              />
          </div>
          { error && <div className='error'>{ error }</div> }
          <div>
            <button type='submit' className='btn btn-primary btn-block'>Sign In</button>
          </div>
          <div>
            <a className='btn btn-google btn-block' onClick={onGoogleClick}>Google Apps</a>
          </div>
        </div>
      </div>
    </form>
  </div>
}
