import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer'

const fragmentShaderPosition= `
      uniform float time;
      uniform float delta;

      void main()  {

        vec2 uv = gl_FragCoord.xy / resolution.xy;
        vec4 tmpPos = texture2D( texturePosition, uv );
        vec3 position = tmpPos.xyz;
        vec3 velocity = texture2D( textureVelocity, uv ).xyz;

        float phase = tmpPos.w;

        phase = mod( ( phase + delta +
          length( velocity.xz ) * delta * 3. +
          max( velocity.y, 0.0 ) * delta * 6. ), 62.83 );

        gl_FragColor = vec4( position + velocity * delta * 15. , phase );

      }
`

const fragmentShaderVelocity = `

      uniform float time;
      uniform float testing;
      uniform float delta; // about 0.016
      uniform float separationDistance; // 20
      uniform float alignmentDistance; // 40
      uniform float cohesionDistance; //
      uniform float freedomFactor;
      uniform vec3 predator;

      const float width = resolution.x;
      const float height = resolution.y;

      const float PI = 3.141592653589793;
      const float PI_2 = PI * 2.0;
      // const float VISION = PI * 0.55;

      float zoneRadius = 4.0;
      float zoneRadiusSquared = 16.0;

      float separationThresh = 0.45;
      float alignmentThresh = 0.65;

      const float SPEED_LIMIT = 7.0;

      float rand( vec2 co ){
        return fract( sin( dot( co.xy, vec2(12.9898,78.233) ) ) * 43758.5453 );
      }

      void main() {

        zoneRadius = separationDistance + alignmentDistance + cohesionDistance;
        separationThresh = separationDistance / zoneRadius;
        alignmentThresh = ( separationDistance + alignmentDistance ) / zoneRadius;
        zoneRadiusSquared = zoneRadius * zoneRadius;


        vec2 uv = gl_FragCoord.xy / resolution.xy;
        vec3 birdPosition, birdVelocity;

        vec3 selfPosition = texture2D( texturePosition, uv ).xyz;
        vec3 selfVelocity = texture2D( textureVelocity, uv ).xyz;

        float dist;
        vec3 dir; // direction
        float distSquared;

        float separationSquared = separationDistance * separationDistance;
        float cohesionSquared = cohesionDistance * cohesionDistance;

        float f;
        float percent;

        vec3 velocity = selfVelocity;

        float limit = SPEED_LIMIT;

        // if (testing == 0.0) {}
        // if ( rand( uv + time ) < freedomFactor ) {}

        // Attract flocks to the center
        vec3 central = vec3( 300., 100., 0. );
        dir = selfPosition - central;
        dist = length( dir );

        dir.y *= 2.5;
        dir.x *= 0.1;
        dir.z *= 0.2;
        velocity -= normalize( dir ) * delta * 4.;

        for ( float y = 0.0; y < height; y++ ) {
          for ( float x = 0.0; x < width; x++ ) {

            vec2 ref = vec2( x + 0.5, y + 0.5 ) / resolution.xy;
            birdPosition = texture2D( texturePosition, ref ).xyz;

            dir = birdPosition - selfPosition;
            dist = length( dir );

            if ( dist < 0.0001 ) continue;

            distSquared = dist * dist;

            if ( distSquared > zoneRadiusSquared ) continue;

            percent = distSquared / zoneRadiusSquared;

            if ( percent < separationThresh ) { // low

              // Separation - Move apart for comfort
              f = ( separationThresh / percent - 1.0 ) * delta;
              velocity -= normalize( dir ) * f;

            } else if ( percent < alignmentThresh ) { // high

              // Alignment - fly the same direction
              float threshDelta = alignmentThresh - separationThresh;
              float adjustedPercent = ( percent - separationThresh ) / threshDelta;

              birdVelocity = texture2D( textureVelocity, ref ).xyz;

              f = ( 0.5 - cos( adjustedPercent * PI_2 ) * 0.5 + 0.5 ) * delta;
              velocity += normalize( birdVelocity ) * f;

            } else {

              // Attraction / Cohesion - move closer
              float threshDelta = 1.0 - alignmentThresh;
              float adjustedPercent = ( percent - alignmentThresh ) / threshDelta;

              f = ( 0.5 - ( cos( adjustedPercent * PI_2 ) * -0.5 + 0.5 ) ) * delta;

              velocity += normalize( dir ) * f;

            }

          }

        }

        // this make tends to fly around than down or up
        // if (velocity.y > 0.) velocity.y *= (1. - 0.2 * delta);

        // Speed Limits
        if ( length( velocity ) > limit ) {
          velocity = normalize( velocity ) * limit;
        }

        gl_FragColor = vec4( velocity, 1.0 );

      }
`



/* TEXTURE WIDTH FOR SIMULATION */
var WIDTH = 16;
var BIRDS = WIDTH * WIDTH;

/* BAKE ANIMATION INTO TEXTURE and CREATE GEOMETRY FROM BASE MODEL */
var BirdGeometry = new THREE.BufferGeometry();
var textureAnimation, durationAnimation, birdMesh, materialShader, vertexPerBird;

function nextPowerOf2( n ) {

  return Math.pow( 2, Math.ceil( Math.log( n ) / Math.log( 2 ) ) );

}

const lerp = function ( value1, value2, amount ) {

  amount = Math.max( Math.min( amount, 1 ), 0 );
  return value1 + ( value2 - value1 ) * amount;

};

function onBirdsLoaded( gltf ) {

  var animations = gltf.animations;
  durationAnimation = Math.round( animations[ 0 ].duration * 60 );
  var birdGeo = gltf.scene.children[ 0 ].geometry;
  var morphAttributes = birdGeo.morphAttributes.position;
  var tHeight = nextPowerOf2( durationAnimation );
  var tWidth = nextPowerOf2( birdGeo.getAttribute( 'position' ).count );
  vertexPerBird = birdGeo.getAttribute( 'position' ).count;
  var tData = new Float32Array( 3 * tWidth * tHeight );

  for ( var i = 0; i < tWidth; i ++ ) {
    for ( var j = 0; j < tHeight; j ++ ) {
      var offset = j * tWidth * 3;

      var curMorph = Math.floor( j / durationAnimation * morphAttributes.length );
      var nextMorph = ( Math.floor( j / durationAnimation * morphAttributes.length ) + 1 ) % morphAttributes.length;
      var lerpAmount = j / durationAnimation * morphAttributes.length % 1;

      if ( j < durationAnimation ) {

        tData[ offset + i * 3 ] = lerp( morphAttributes[ curMorph ].array[ i * 3 ], morphAttributes[ nextMorph ].array[ i * 3 ], lerpAmount );
        tData[ offset + i * 3 + 1 ] = lerp( morphAttributes[ curMorph ].array[ i * 3 + 1 ], morphAttributes[ nextMorph ].array[ i * 3 + 1 ], lerpAmount );
        tData[ offset + i * 3 + 2 ] = lerp( morphAttributes[ curMorph ].array[ i * 3 + 2 ], morphAttributes[ nextMorph ].array[ i * 3 + 2 ], lerpAmount );

      }
    }
  }

  textureAnimation = new THREE.DataTexture( tData, tWidth, tHeight, THREE.RGBFormat, THREE.FloatType );
  textureAnimation.needsUpdate = true;

  var vertices = [], color = [], reference = [], seeds = [], indices = [];
  var totalVertices = birdGeo.getAttribute( 'position' ).count * 3 * BIRDS;
  for ( var i = 0; i < totalVertices; i ++ ) {

    var bIndex = i % ( birdGeo.getAttribute( 'position' ).count * 3 );
    vertices.push( birdGeo.getAttribute( 'position' ).array[ bIndex ] );
    color.push( birdGeo.getAttribute( 'color' ).array[ bIndex ] );

  }

  var r = Math.random();
  for ( var i = 0; i < birdGeo.getAttribute( 'position' ).count * BIRDS; i ++ ) {

    var bIndex = i % ( birdGeo.getAttribute( 'position' ).count );
    var bird = Math.floor( i / birdGeo.getAttribute( 'position' ).count );
    if ( bIndex == 0 ) r = Math.random();
    var j = ~ ~ bird;
    var x = ( j % WIDTH ) / WIDTH;
    var y = ~ ~ ( j / WIDTH ) / WIDTH;
    reference.push( x, y, bIndex / tWidth, durationAnimation / tHeight );
    seeds.push( bird, r, Math.random(), Math.random() );

  }

  for ( var i = 0; i < birdGeo.index.array.length * BIRDS; i ++ ) {

    var offset = Math.floor( i / birdGeo.index.array.length ) * ( birdGeo.getAttribute( 'position' ).count );
    indices.push( birdGeo.index.array[ i % birdGeo.index.array.length ] + offset );

  }

  BirdGeometry.setAttribute( 'position', new THREE.BufferAttribute( new Float32Array( vertices ), 3 ) );
  BirdGeometry.setAttribute( 'birdColor', new THREE.BufferAttribute( new Float32Array( color ), 3 ) );
  BirdGeometry.setAttribute( 'color', new THREE.BufferAttribute( new Float32Array( color ), 3 ) );
  BirdGeometry.setAttribute( 'reference', new THREE.BufferAttribute( new Float32Array( reference ), 4 ) );
  BirdGeometry.setAttribute( 'seeds', new THREE.BufferAttribute( new Float32Array( seeds ), 4 ) );

  BirdGeometry.setIndex( indices );

  init();
  animate();
}

var container, stats;
var camera, scene, renderer;
var mouseX = 0, mouseY = 0;

var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;

var BOUNDS = 100, BOUNDS_HALF = BOUNDS / 2;

var last = performance.now();

var gpuCompute;
var velocityVariable;
var positionVariable;
var positionUniforms;
var velocityUniforms;

var afterRenderCallback;
var firstRender = true;
var iterations = 0;

function init() {
  initComputeRenderer();

  document.addEventListener( 'mousemove', onDocumentMouseMove, false );
  document.addEventListener( 'touchstart', onDocumentTouchStart, false );
  document.addEventListener( 'touchmove', onDocumentTouchMove, false );

  velocityUniforms[ "separationDistance" ].value = 20.0;
  velocityUniforms[ "alignmentDistance" ].value = 20.0;
  velocityUniforms[ "cohesionDistance" ].value = 20.0;
  velocityUniforms[ "freedomFactor" ].value = 0.75;
  if ( materialShader ) materialShader.uniforms[ "size" ].value = 0.1
  BirdGeometry.setDrawRange( 0, vertexPerBird * BIRDS );

  initBirds();
}

function initComputeRenderer() {

  gpuCompute = new GPUComputationRenderer( WIDTH, WIDTH, renderer );

  var dtPosition = gpuCompute.createTexture();
  var dtVelocity = gpuCompute.createTexture();
  fillPositionTexture( dtPosition );
  fillVelocityTexture( dtVelocity );

  velocityVariable = gpuCompute.addVariable( "textureVelocity", fragmentShaderVelocity, dtVelocity );
  positionVariable = gpuCompute.addVariable( "texturePosition", fragmentShaderPosition, dtPosition );

  gpuCompute.setVariableDependencies( velocityVariable, [ positionVariable, velocityVariable ] );
  gpuCompute.setVariableDependencies( positionVariable, [ positionVariable, velocityVariable ] );

  positionUniforms = positionVariable.material.uniforms;
  velocityUniforms = velocityVariable.material.uniforms;

  positionUniforms[ "time" ] = { value: 0.0 };
  positionUniforms[ "delta" ] = { value: 0.0 };
  velocityUniforms[ "time" ] = { value: 1.0 };
  velocityUniforms[ "delta" ] = { value: 0.0 };
  velocityUniforms[ "testing" ] = { value: 1.0 };
  velocityUniforms[ "separationDistance" ] = { value: 1.0 };
  velocityUniforms[ "alignmentDistance" ] = { value: 1.0 };
  velocityUniforms[ "cohesionDistance" ] = { value: 1.0 };
  velocityUniforms[ "freedomFactor" ] = { value: 1.0 };

  velocityVariable.material.defines.BOUNDS = BOUNDS.toFixed( 2 );

  velocityVariable.wrapS = THREE.RepeatWrapping;
  velocityVariable.wrapT = THREE.RepeatWrapping;
  positionVariable.wrapS = THREE.RepeatWrapping;
  positionVariable.wrapT = THREE.RepeatWrapping;

  var error = gpuCompute.init();

  if ( error !== null ) {

    console.error( error );

  }

}

function initBirds() {

  var geometry = BirdGeometry;

  var m = new THREE.MeshStandardMaterial( {
    vertexColors: true,
    flatShading: true,
    roughness: 0.0,
    metalness: 0.6
  } );

  m.onBeforeCompile = ( shader ) => {

    shader.uniforms.texturePosition = { value: null };
    shader.uniforms.textureVelocity = { value: null };
    shader.uniforms.textureAnimation = { value: textureAnimation };
    shader.uniforms.time = { value: 1.0 };
    shader.uniforms.size = { value: 0.1 };
    shader.uniforms.delta = { value: 0.0 };

    var token = '#define STANDARD';

    var insert = /* glsl */`
      attribute vec4 reference;
      attribute vec4 seeds;
      attribute vec3 birdColor;
      uniform sampler2D texturePosition;
      uniform sampler2D textureVelocity;
      uniform sampler2D textureAnimation;
      uniform float size;
      uniform float time;
    `;

    shader.vertexShader = shader.vertexShader.replace( token, token + insert );

    var token = '#include <begin_vertex>';

    var insert = /* glsl */`
      vec4 tmpPos = texture2D( texturePosition, reference.xy );
      vec3 pos = tmpPos.xyz;
      vec3 velocity = normalize(texture2D( textureVelocity, reference.xy ).xyz);
      vec3 aniPos = texture2D( textureAnimation, vec2( reference.z, mod( ( time*.5 + seeds.x ) * ( ( 0.0004 + seeds.y / 100000.0) + normalize( velocity ) / 200000.0 ), reference.w ) ) ).xyz;
      vec3 newPosition = position;
      newPosition = mat3( modelMatrix ) * ( newPosition + aniPos );
      newPosition *= size + seeds.y * size * 0.2;
      velocity.z *= -1.;
      float xz = length( velocity.xz );
      float xyz = 1.;
      float x = sqrt( 1. - velocity.y * velocity.y );
      float cosry = velocity.x / xz;
      float sinry = velocity.z / xz;
      float cosrz = x / xyz;
      float sinrz = velocity.y / xyz;
      mat3 maty =  mat3( cosry, 0, -sinry, 0    , 1, 0     , sinry, 0, cosry );
      mat3 matz =  mat3( cosrz , sinrz, 0, -sinrz, cosrz, 0, 0     , 0    , 1 );
      newPosition =  maty * matz * newPosition;
      newPosition += pos;
      vec3 transformed = vec3( newPosition );
    `;

    shader.vertexShader = shader.vertexShader.replace( token, insert );

    materialShader = shader;

  };

  birdMesh = new THREE.Mesh( geometry, m );
  birdMesh.rotation.y = Math.PI / 2;

  scene.add( birdMesh );
}

function fillPositionTexture( texture ) {
  var xOffset = -500 + Math.random() * 1000

  var theArray = texture.image.data;

  for ( var k = 0, kl = theArray.length; k < kl; k += 4 ) {

    var z = Math.random() * BOUNDS - BOUNDS_HALF - 200;
    var y = Math.random() * BOUNDS - BOUNDS_HALF + 200;
    var x = Math.random() * BOUNDS - BOUNDS_HALF + xOffset;

    theArray[ k + 0 ] = z;
    theArray[ k + 1 ] = y;
    theArray[ k + 2 ] = x;
    theArray[ k + 3 ] = 1;

  }

}

function fillVelocityTexture( texture ) {

  var theArray = texture.image.data;

  for ( var k = 0, kl = theArray.length; k < kl; k += 4 ) {

    var x = Math.random() - 0.5;
    var y = Math.random() - 0.5;
    var z = Math.random() - 0.5;

    theArray[ k + 0 ] = x * 10;
    theArray[ k + 1 ] = y * 10;
    theArray[ k + 2 ] = z * 10;
    theArray[ k + 3 ] = 1;

  }

}

function onDocumentMouseMove( event ) {

  mouseX = event.clientX - windowHalfX;
  mouseY = event.clientY - windowHalfY;

}

function onDocumentTouchStart( event ) {

  if ( event.touches.length === 1 ) {

    event.preventDefault();

    mouseX = event.touches[ 0 ].pageX - windowHalfX;
    mouseY = event.touches[ 0 ].pageY - windowHalfY;

  }

}

function onDocumentTouchMove( event ) {

  if ( event.touches.length === 1 ) {

    event.preventDefault();

    mouseX = event.touches[ 0 ].pageX - windowHalfX;
    mouseY = event.touches[ 0 ].pageY - windowHalfY;

  }

}

function animate() {

  requestAnimationFrame( animate );

  render();

}

function render() {
  var now = performance.now();
  var delta = ( now - last ) / 1000;

  if ( delta > 1 ) delta = 1; // safety cap on large deltas
  last = now;

  positionUniforms[ "time" ].value = now;
  positionUniforms[ "delta" ].value = delta;
  velocityUniforms[ "time" ].value = now;
  velocityUniforms[ "delta" ].value = delta;
  if ( materialShader ) materialShader.uniforms[ "time" ].value = now;
  if ( materialShader ) materialShader.uniforms[ "delta" ].value = delta;

  gpuCompute.compute();

  if ( materialShader ) materialShader.uniforms[ "texturePosition" ].value = gpuCompute.getCurrentRenderTarget( positionVariable ).texture;
  if ( materialShader ) materialShader.uniforms[ "textureVelocity" ].value = gpuCompute.getCurrentRenderTarget( velocityVariable ).texture;

  // For reasons unknown the flamingos do not render if the water is added first.
  if (iterations === 1) {
    afterRenderCallback()
  }

  iterations++;
}

var gltfs = [ 'models/gltf/Parrot.glb', 'gltf/Flamingo.glb' ];
var colors = [ 0xccFFFF, 0xffdeff ];
var sizes = [ 0.2, 0.1 ];
var selectModel = 1; // Always choose model

const renderFlamingos = (parentScene, parentRenderer, callback) => {
  scene = parentScene
  renderer = parentRenderer
  new GLTFLoader().load( gltfs[ selectModel ], (gltf) => {
    afterRenderCallback = callback;
    onBirdsLoaded(gltf)
  })
}

export default renderFlamingos

